<template>
  <v-form @submit.prevent="submitResponse">
    <div>
      <p class="mb-3">
        The data table below was acquired for the reaction of tert-butyl bromide with water:
      </p>

      <p class="pl-8 mb-5">
        <stemble-latex content="$\ce{(CH3)3CBr(aq) + H2O(l) -> (CH3)3COH(aq) + HBr(aq)}$" />
      </p>

      <v-simple-table>
        <tbody>
          <tr class="mb-5">
            <td style="font-weight: bold; text-align: left">Time (h)</td>
            <td v-for="(option, index) in row1" :key="index" style="text-align: center">
              {{ option }}
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold; text-align: left">
              <stemble-latex :content="row2[0]" />
            </td>
            <td v-for="(option, index) in row2vals" :key="index" style="text-align: center">
              {{ option }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div class="mt-5">
      <label for="answerPartA" class="mb-2">
        a) Describe a graphical method you would use to determine the order of this reaction using
        the data that was given.
      </label>
      <v-textarea v-model="inputs.answerPartA" class="mt-2" outlined no-resize />
    </div>
    <div>
      <label for="answerPartB" class="mb-2"> b) What is the order of this reaction? </label>
      <calculation-input
        v-model="inputs.answerPartB"
        prepend-text="$\text{Reaction order:}$"
        :disabled="!allowEditing"
        class="mb-5 mt-2"
      />
      <label for="answerPartC" class="mb-2">
        c) Calculate the rate constant for this reaction.
      </label>
      <calculation-input
        v-model="inputs.answerPartC"
        prepend-text="$\text{k:}$"
        :disabled="!allowEditing"
        class="mb-5 mt-2"
      />
      <label for="answerPartD" class="mb-2">
        d) What are the units of the rate constant for this reaction?
      </label>
      <v-textarea
        v-model="inputs.answerPartD"
        label="Units of k"
        class="mt-2"
        outlined
        rows="1"
        row-height="2"
        no-resize
      />
      <label for="answerPartE" class="mb-2">
        e) What concentration of tert-butyl bromide will remain after the reaction has been allowed
        to run for 13.0 hours?
      </label>
      <calculation-input
        id="answerPartE"
        v-model="inputs.answerPartE"
        prepend-text="$\ce{[(CH3)3CBr]}:$"
        append-text="$\text{mM}$"
        :disabled="!allowEditing"
        class="mt-2"
      />
    </div>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput';
import {submitResponse} from '@/tasks/api/task-responses';

export default {
  name: 'OttawaGotoHW7Q9',
  methods: {submitResponse},
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        answerPartA: null,
        answerPartB: null,
        answerPartC: null,
        answerPartD: null,
        answerPartE: null,
      },
      row1: ['0.00', '1.05', '2.07', '3.33', '6.10', '10.30'],
      row2: ['$\\ce{[(CH3)3CBr]}$ (mM)'],
      row2vals: ['34.6', '29.9', '25.9', '21.3', '11.8', '6.90'],
    };
  },
};
</script>
